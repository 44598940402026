<div class="laptop" *ngIf="!isMobile" id="smartscale">
<div class="overlay-error" *ngIf="!dataExists">
  <p><img src="assets/images/warning.png" style="width: 50px;"><br>Cannot build report, No Data Found<br>Please take a Smartscale Reading.</p>
</div>
<div id="elementToCapture" #elementToCapture>
    <!-- <div id="loader">
        <div class="spinner"></div>
      </div> -->
<div class="header">
    <!-- <img src="assets/images/actofit_logo.svg" style="place-self: center; width: 100%;"> -->
    <img id="logoImage" style="place-self: center; width: 100%; height: 112px;">
    <span></span>
    <p class="heading" style="place-self: center;">Scan QR Code to view your reading in your mobile app</p>
    <img src="assets/images/qr.png" style="width:80%; place-self: center;">
</div>
<!---Personal Details-->
<mat-card class="text-center little-profile" style="margin: 0px;">
    <!-- <img mat-card-image src="assets/images/background/profile-bg.jpg" alt="Photo of a Shiba Inu"> -->
    <mat-card-content fxLayout="row" fxLayoutWrap="wrap" style="padding: 10px 25px!important;">
        <!-- <div class="pro-img2" fxFlex.gt-xs="15%">
            <img src="assets/images/users/4.jpg" width="100" alt="user" class="img-circle">
        </div> -->
        <div fxFlex.gt-xs="25%" fxFlex="100" class="profile-block">
            <!-- <img src="assets/images/age.png" class="profile-icon"> -->
            <p class="profile-head">Name:</p>
            <p class="profile-text"> {{userProfile.data.name}}</p>
        </div>
        <div fxLayout="row" fxLayoutWrap="wrap" class="m-t-3" fxFlex.gt-xs="75%">
            <div fxFlex.gt-sm="25%" fxFlex.gt-xs="33.33%" fxFlex="100" class="profile-block">
                <!-- <img src="assets/images/age.png" class="profile-icon"> -->
                <p class="profile-head">Age:</p>
                <p class="profile-text"> {{age}} yrs</p>
            </div>
            <div fxFlex.gt-sm="25%" fxFlex="100" class="profile-block">
                <!-- <img src="assets/images/height.png" class="profile-icon"> -->
                <p class="profile-head">Height:</p>
                <p class="profile-text"> {{userProfile.data.height}} cm</p>
            </div>
            <div fxFlex.gt-sm="25%" fxFlex.gt-xs="33.33%" fxFlex="100" class="profile-block">
                <p class="profile-head">Gender:</p>
                <p class="profile-text" style="text-transform: capitalize;">{{userProfile.data.gender}}</p>            </div>
            <div fxFlex.gt-sm="25%" fxFlex.gt-xs="33.33%" fxFlex="100" class="profile-block">
                <p class="profile-head">Date:</p>
                <p class="profile-text">{{formattedDateNow}}</p>
            </div>
        </div>
    </mat-card-content>
</mat-card>
<button (click)="generatePDF()" class="print-button heading ">Print</button>
<div class="upper-section" fxLayout="row" fxLayoutWrap="wrap">
<div class="analysis-section" fxFlex.gt-sm="50%">
<!---body-composition-->
<h3 class="heading">Body Composition Analysis</h3>
<mat-card class="card-main">
    <div class="table-row" fxLayout="row" fxLayoutWrap="wrap">
            <div class="bca-img" fxFlex.gt-sm="10%">
                <img src="assets/images/bodyfat.png">
            </div>
            <div class="bca-text" fxFlex.gt-sm="90%">
            <div class="bca-details" fxFlex.gt-sm="85%">
                <span class="heading" style="margin-left: 0px;">Body Fat</span>
                <p class="m-0">Total fat in your body that include fat around your organs and below your organs and below your skin.</p>    
            </div>
            <div class="col-2" fxFlex.gt-sm="15%" style="background-color: #F6F9FC; border-radius: 0rem 1rem 0rem 0rem;">
            <p>{{(userdata.body_fat/100)*userdata.weight | number : '.2-2'}}Kg</p>
            </div>
            </div>
    </div>
    <div class="table-row" fxLayout="row" fxLayoutWrap="wrap">
        <div class="bca-img" fxFlex.gt-sm="10%">
            <img src="assets/images/fatfree.png">
        </div>
        <div class="bca-text" fxFlex.gt-sm="90%">
        <div class="bca-details" fxFlex.gt-sm="85%">
            <span class="heading" style="margin-left: 0px;">Fat Free Weight</span>
            <p class="m-0">Your muscle, Bone, Body water and other Tissue weight</p>    
        </div>
        <div class="col-2" fxFlex.gt-sm="15%" style="background-color: #F6F9FC;">
        <p>{{userdata.weight-(userdata.body_fat/100)*userdata.weight  | number : '.2-2'}}Kg</p>
        </div>
        </div>
</div>
<div class="table-row" fxLayout="row" fxLayoutWrap="wrap">
    <div class="bca-img" fxFlex.gt-sm="10%">
        <img src="assets/images/weight.png">
    </div>
    <div class="bca-text" fxFlex.gt-sm="90%" style="border: none;">
    <div class="bca-details" fxFlex.gt-sm="85%">
        <span class="heading" style="margin-left: 0px;">Weight</span>
        <p class="m-0">Sum of the above</p>    
    </div>
    <div class="col-2" fxFlex.gt-sm="15%" style="background-color: #F6F9FC; border-radius: 0rem 0rem 1rem 0rem;">
    <p>{{userdata.weight | number : '.2-2'}}Kg</p>
    </div>
    </div>
</div>
    <!-- <div class="table-row" fxLayout="row" fxLayoutWrap="wrap">
        <div class="col-1" fxFlex.gt-sm="65%">
            Fat Free Weight
        </div>
        <div class="col-2" fxFlex.gt-sm="35%">
            45.59 Kg
        </div>
    </div>
    <div class="table-row" fxLayout="row" fxLayoutWrap="wrap">
        <div class="col-1" fxFlex.gt-sm="65%">
            Weight (64.60~78.96)
        </div>
        <div class="col-2" fxFlex.gt-sm="35%">
            58.00 Kg
        </div>
    </div> -->
</mat-card>

<!--Muscle Fat-->
<h3 class="heading" style="margin-top: 15px;">Muscle Fat Analysis</h3>
<mat-card class="card-main mfa">
    <div class="table-row">
        <div fxLayout="row" fxLayoutWrap="wrap" class="table-row-2">
        <div class="col-1" fxFlex.gt-sm="25%">
            Weight ({{userdata.weight | number : '.2-2'}}Kg)
        </div>
        <div class="col-2 m-t-5" fxFlex.gt-sm="75%" fxLayout="row" fxLayoutWrap="wrap">
            <div class="slider-section" fxFlex.gt-sm="20%" width="20%">   
                <div class="slider slider-1"></div>
                <p class="slider-text-in">Seriously Low</p>    
            </div>
            <div class="slider-section" fxFlex.gt-sm="20%" width="20%">
                <p class="slider-text-up">{{this.sw * 0.80 | number : '.1-1'}}</p>
                <div class="slider slider-2"></div>
                <p class="slider-text-in">Low</p>    
            </div>
            <div class="slider-section" fxFlex.gt-sm="20%" width="20%">
                <p class="slider-text-up">{{this.sw * 0.89 | number : '.1-1'}}</p>
                <div class="slider slider-3"></div>
                <p class="slider-text-in">Standard</p>    
            </div>
            <div class="slider-section" fxFlex.gt-sm="20%" width="20%">
                <p class="slider-text-up">{{this.sw * 1.09 | number : '.1-1'}}</p>
                <div class="slider slider-4"></div>
                <p class="slider-text-in">High</p>    
            </div>
            <div class="slider-section" fxFlex.gt-sm="20%" width="20%">
                <p class="slider-text-up">{{this.sw * 1.20 | number : '.1-1'}}</p>
                <div class="slider slider-5"></div>
                <p class="slider-text-in">Exceed</p>    
            </div>
            <!-- <div class="slider-2" fxFlex.gt-sm="20%" width="20%"></div>
            <div class="slider-3" fxFlex.gt-sm="20%" width="20%"></div>
            <div class="slider-4" fxFlex.gt-sm="20%" width="20%"></div>
            <div class="slider-5" fxFlex.gt-sm="20%" width="20%"></div> -->
            <!-- <p class="slider-text" id="first-text">57.4</p>
            <p class="slider-text" id="second-text">64.6</p>
            <p class="slider-text" id="third-text">79.0</p>
            <p class="slider-text" id="fourth-text">86.1</p> -->
            <img src="assets/images/marker.png" style="width: 15px; top: 15px;" [style.left.%]="weightslider" class="slider-dot">
        </div>
        </div>
        <div fxLayout="row" fxLayoutWrap="wrap" class="table-row-2">
        <div class="col-1" fxFlex.gt-sm="25%">
            SMM ({{userdata.skeletal_muscle | number : '.2-2'}}%)<br>
            <span class="sub-text">(Skeletal Muscle Mass)</span>
        </div>
        <div class="col-2 m-t-5" fxFlex.gt-sm="75%" fxLayout="row" fxLayoutWrap="wrap">
            <div class="slider-section" fxFlex.gt-sm="33.33%" width="33.33%">
                <div class="slider slider-2"></div>
                <p class="slider-text-in">Low</p>    
            </div>
            <div class="slider slider-section" fxFlex.gt-sm="33.33%" width="33.33%">
                <p class="slider-text-up">{{this.smm | number : '.1-1'}}</p>
                <div class="slider-3"></div>
                <p class="slider-text-in">Standard</p>    
            </div>
            <div class="slider slider-section" fxFlex.gt-sm="33.33%" width="33.33%">
                <p class="slider-text-up">{{this.smm + 10 | number : '.1-1'}}</p>
                <div class="slider-4"></div>
                <p class="slider-text-in">High</p>    
            </div>
            <!-- <p class="slider-text" id="first-text" style="left: 32%">49.0</p>
            <p class="slider-text" id="second-text" style="left: 65%">59.0</p> -->
            <!-- <img src="assets/images/slider-dot.png" style="width: 21px; top: -5.5px;" [style.left.%]="weightslider" class="slider-dot"> -->
            <img src="assets/images/marker.png" style="width: 15px; top: 15px;" [style.left.%]="smmslider" class="slider-dot">
        </div>
        </div>
        <div fxLayout="row" fxLayoutWrap="wrap" class="table-row-2">
            <div class="col-1" fxFlex.gt-sm="25%">
                BFM ({{ userdata.body_fat*weight/100 | number : '.2-2'}} Kg)<br>
                <span class="sub-text">(Body Fat Mass)</span>
            </div>
            <div class="col-2 m-t-5" fxFlex.gt-sm="75%" fxLayout="row" fxLayoutWrap="wrap">
                <div class="slider-section" fxFlex.gt-sm="25%" width="25%">
                    <div class="slider slider-2"></div>
                    <p class="slider-text-in">Low</p>    
                </div>
                <div class="slider-section" fxFlex.gt-sm="25%" width="25%">
                    <p class="slider-text-up">{{bfp | number : '.1-1'}}</p>
                    <div class="slider slider-3"></div>
                    <p class="slider-text-in">Standard</p>    
                </div>
                <div class="slider-section" fxFlex.gt-sm="25%" width="25%">
                    <p class="slider-text-up">{{bfp + 10 | number : '.1-1'}}</p>
                    <div class="slider slider-4"></div>
                    <p class="slider-text-in">High</p>    
                </div>
                <div class="slider-section" fxFlex.gt-sm="25%" width="25%">
                    <p class="slider-text-up">{{bfp + 15 | number : '.1-1'}}</p>
                    <div class="slider slider-5"></div>
                    <p class="slider-text-in">Exceed</p>    
                </div>
                <!-- <p class="slider-text" id="second-text" style="left: 23.5%">11.0</p>
                <p class="slider-text" id="third-text" style="left: 48.5%">22.0</p>
                <p class="slider-text" id="fourth-text" style="left: 73.5%">26.0</p>    
                <img src="assets/images/slider-dot.png" style="width: 21px; top: -5.5px;" [style.left.%]="weightslider" class="slider-dot"> -->
                <img src="assets/images/marker.png" style="width: 15px; top: 15px;" [style.left.%]="pdfslider" class="slider-dot">

            </div>
            </div>
    </div>
</mat-card>

<!--Obesity Analysis-->
<h3 class="heading" style="margin-top: 15px;">Obesity Analysis</h3>
<mat-card class="card-main mfa">
    <div class="table-row">
        <div fxLayout="row" fxLayoutWrap="wrap" class="table-row-2">
        <div class="col-1" fxFlex.gt-sm="25%">
            BMI ({{userdata.bmi | number : '.2-2'}})<br>
            <span class="sub-text">(Body Mass Index)</span>
        </div>
        <div class="col-2 m-t-5" fxFlex.gt-sm="75%" style="margin-left: 20px;" fxLayout="row" fxLayoutWrap="wrap">
            <div class="slider-section" fxFlex.gt-sm="33.33%" width="33.33%">
                <div class="slider slider-2" ></div>
                <p class="slider-text-in">Low</p>    
            </div>
            <div class="slider-section" fxFlex.gt-sm="33.33%" width="33.33%">
                <p class="slider-text-up">18.5</p>
                <div class="slider slider-3"></div>
                <p class="slider-text-in">Standard</p>    
            </div>
            <div class="slider-section" fxFlex.gt-sm="33.33%" width="33.33%">
                <p class="slider-text-up">25.0</p>
                <div class="slider slider-4" ></div>
                <p class="slider-text-in">High</p>    
            </div>
            <!-- <p class="slider-text" id="first-text" style="left: 32%">18.5</p>
            <p class="slider-text" id="second-text" style="left: 65%">25.0</p>
            <img src="assets/images/slider-dot.png" style="width: 21px; top: -5.5px;" [style.left.%]="weightslider" class="slider-dot"> -->
            <img src="assets/images/marker.png" style="width: 15px; top: 15px;" [style.left.%]="bmislider" class="slider-dot">

        </div>
        </div>
        <div fxLayout="row" fxLayoutWrap="wrap" class="table-row-2">
            <div class="col-1" fxFlex.gt-sm="25%">
                PBF ({{userdata.body_fat | number : '.2-2'}}%)<br>
                <span class="sub-text"> (Percent Body Fat)</span>
            </div>
            <div class="col-2 m-t-5" fxFlex.gt-sm="75%" style="margin-left: 20px;" fxLayout="row" fxLayoutWrap="wrap">
                <div class="slider-section" fxFlex.gt-sm="25%" width="25%">
                    <div class="slider slider-2" ></div>
                    <p class="slider-text-in">Low</p>    
                </div>
                <div class="slider-section" fxFlex.gt-sm="25%" width="25%">
                    <p class="slider-text-up">{{bfp*weight/100 | number : '.1-1'}}</p>
                    <div class="slider slider-3"></div>
                    <p class="slider-text-in">Standard</p>    
                </div>
                <div class="slider-section" fxFlex.gt-sm="25%" width="25%">
                    <p class="slider-text-up">{{(bfp*weight/100) + 10 | number : '.1-1'}}</p>
                    <div class="slider slider-4"></div>
                    <p class="slider-text-in">High</p>    
                </div>
                <div class="slider-section" fxFlex.gt-sm="25%" width="25%">
                    <p class="slider-text-up">{{(bfp*weight/100) + 15 | number : '.1-1'}}</p>
                    <div class="slider slider-5"></div>
                    <p class="slider-text-in">Exceed</p>    
                </div>
                <!-- <p class="slider-text" id="second-text" style="left: 23.5%">6.4</p>
                <p class="slider-text" id="third-text" style="left: 48.5%">12.8</p>
                <p class="slider-text" id="fourth-text" style="left: 73.5%">15.1</p>    
                <img src="assets/images/slider-dot.png" style="width: 21px; top: -5.5px;" [style.left.%]="weightslider" class="slider-dot"> -->
                <img src="assets/images/marker.png" style="width: 15px; top: 15px;" [style.left.%]="bfmslider" class="slider-dot">

            </div>
            </div>
        
    </div>
</mat-card>
<!--Segmental analysis-->
</div>
<div class="actofit-health" fxFlex.gt-sm="50%">
<h3 class="heading">Actofit Health Score</h3>
<mat-card class="card-main">
    <!-- <canvas id="actoscore"></canvas> -->
    <div>
    <div class="score-donut">
    <div class="card">
        <div class="percent">
          <!-- <svg>
            <circle cx="105" cy="105" r="50"></circle>
            <circle cx="105" cy="105" r="50" [style.--percent]="userdata.health_score"></circle>
          </svg> -->
          <div class="number">
            <h3 id="score">{{userdata.health_score | number : '.2-2'}}<span>/100 points</span></h3>
          </div>
        </div>
        <!-- <div class="title">
            <h2>Points</h2>
          </div> -->
    </div>
</div>
<p style="font-style: italic; font-family: 'Roboto', sans-serif; font-size: 0.7rem; text-align: center; border-bottom: 0.45px solid #EAEAEA; width: 90%; margin: auto; padding-bottom: 15px;">*Total score that reflects the evaluation of body composition. A muscular person may score over 100 points.</p>

      <div class="score-details">
        <h4 class="heading" style="margin: 0px 10px 10px 10px">Results Interpretation</h4>
        <div class="ind-score" id="ind-score">
            <h3 class="m-0 sub-heading">Body Composition Analysis</h3>
            <p class="m-0 sub-text" id="ind-sub">Body weight is the sum of body fat free mass, which is composed of Dry Lean Mass, Total Body Water, Bone Mass & other Tissue</p>
        </div>
        <div class="ind-score">
            <h3 class="m-0 sub-heading">Muscle-Fat Analysis</h3>
            <p class="m-0 sub-text">The longer the Skeletal Muscle Mass bar is compared to the Body Fat Mass bar, the stronger the body is</p>
        </div>
        <div class="ind-score">
            <h3 class="m-0 sub-heading">Obesity Analysis</h3>
            <p class="m-0 sub-text">BMI is an index used to determine obesity by using height and weight. PBF is the percentage of body fat compared to body weight</p>
        </div>
        <div class="ind-score">
            <h3 class="m-0 sub-heading">Segmental Body Analysis</h3>
            <p class="m-0 sub-text">Evaluates whether the amount of muscle and fat is adequately distributed throughout the body</p>
        </div>
            <div class="ind-score">
                <h3 class="m-0 sub-heading">Body Composition History</h3>
                <p class="m-0 sub-text">Track the history of the body compositional change. Take the Actofit Test periodically to monitor your progress.</p>
            </div>
            <div class="ind-score" >
                <h3 class="m-0 sub-heading">Body Fat-Weight Control</h3>
                <p class="m-0 sub-text">Body Fat Mass : <span class="standard">Standard</span> 
                Weight: +6.60Kg
                (+) Means to gain fat/ lean mass · (-) Means to loose fat/lean mass
                </p>
            </div>
            <div class="ind-score">
                <h3 class="m-0 sub-heading">Bone Mass: {{userdata.bone_mass | number : '.2-2'}}Kg <span id="bone-mass"></span></h3>
                <p class="m-0 sub-text">This is the weight of total bone tissue (Bone minerals + Bone matrix) in your body. Higher bone mass indicates better bone health.</p>
            </div>
            <div class="ind-score">
                <h3 class="m-0 sub-heading">Body Water: {{userdata.body_water | number : '.2-2'}}% 33.41 Ltr <span class="standard">Standard</span></h3>
                <p class="m-0 sub-text">It’s the total amount of fluid in the body expressed as % of total weight. Lower body water % affects the essential body processes.</p>
            </div>
            <div class="ind-score">
                <h3 class="m-0 sub-heading">Visceral Fat: {{userdata.visceral_fat}} <span  id="visceral-fat"></span></h3>
                <p class="m-0 sub-text">Visceral fat is located deep in the core abdominal area, surrounding and protecting the vital organs. Healthy level of visceral fat directly reduces the risk of certainn diseases.</p>
            </div>
         
            <div class="ind-score">
                <h3 class="m-0 sub-heading">Subcutaneous Fat: {{userdata.subcutaneous_fat | number : '.2-2'}}% <span id="subcutaneous-fat"></span></h3>
                <p class="m-0 sub-text">It is the proportional weight of fat below the skin to the total body weight. Higher subcutaneous fat value is an indicator of bad physical health.</p>
            </div>
            <div class="ind-score">
                <h3 class="m-0 sub-heading">Protein: {{userdata.protein | number : '.2-2'}}% <span id="protein">Low</span></h3>
                <p class="m-0 sub-text">It is the proportional weight of body protein components to the total body weight. Adequate protein levels are essential for normal body function.</p>
            </div>
            <div class="ind-score">
                <h3 class="m-0 sub-heading">Metabolic Age: {{userdata.metabolic_age}} Yrs <span id="metabolic-age"></span></h3>
                <p class="m-0 sub-text">It is the predicted age of the metabolism of your body. If your metabolic age higher than your actual age, its an indication that you need to improve metabolic rate.</p>
            </div>
        
            <div class="ind-score">
                <h3 class="m-0 sub-heading">Basal Metabolic Rate: {{userdata.bmr}} kcal <span id="bmr"></span></h3>
                <p class="m-0 sub-text">The BMR or Basal Metabolic Rate is daily minimum of energy or calories your body requires when at rest (including sleeping) in order to function effectively</p>
            </div>
      </div>
      </div>
</mat-card>
</div>
</div>


<!---Analysis Data-->
<div class="analysis-section-1" fxLayout="row" fxLayoutWrap="wrap">
<div class="segmental-graph" fxFlex.gt-sm="50%">
    <h3 class="heading">Body Composition History</h3>
<mat-card class="card-main sba" style="height: 500px">
    <div class="table-row" fxLayout="row" fxLayoutWrap="wrap">
        <div class="col-1 heading" fxFlex.gt-sm="25%">
            Weight
        </div>
        <div class="col-2" fxFlex.gt-sm="75%" style="border-radius: 0rem 1rem 0rem 0rem;">
            <canvas id="weightChart" style="border-radius: 0rem 1rem 0rem 0rem;"></canvas>
        </div>
    </div>
    <div class="table-row" fxLayout="row" fxLayoutWrap="wrap">
        <div class="col-1 heading" fxFlex.gt-sm="25%">
            SMM <br>
            <span class="sub-text">Skeletal Muscle Mass</span>
        </div>
        <div class="col-2" fxFlex.gt-sm="75%">
            <canvas id="smmChart"></canvas>
        </div>
    </div>
    <div class="table-row" fxLayout="row" fxLayoutWrap="wrap">
        <div class="col-1 heading" fxFlex.gt-sm="25%">
            BFM<br> 
            <span class="sub-text">Body Fat Mass</span>
        </div>
        <div class="col-2" fxFlex.gt-sm="75%" >
            <canvas id="bfChart" ></canvas>
        </div>
    </div>
    <div class="table-row" fxLayout="row" fxLayoutWrap="wrap">
        <div class="col-1 heading" fxFlex.gt-sm="25%">
        </div>
        <div class="col-2" fxFlex.gt-sm="75%" style="border-radius: 0rem 0rem 1rem 0rem; background-color: transparent;">
            <canvas id="testChart" style="background-color: transparent; border-bottom: none;"></canvas>
        </div>
    </div>
</mat-card>
</div>
<div class="segmental" fxFlex.gt-sm="50%">
    <h3 class="heading">Segmental Analysis</h3>
<mat-card class="card-main"  fxLayout="row" fxLayoutWrap="wrap" id="segmental-card" style="height: 500px">
    <div class="blur-overlay" id="fat-overlay">
        <p>To see segmental analysis use Smartscale Pro and Pro Max</p>
    </div>
    <div class="analysiscol" fxFlex.gt-sm="50%" *ngIf="this.isPresentInAny">
        <h4 class="heading" style="text-align: center;">Fat Analysis</h4>
        <img *ngIf="userProfile.data.gender === 'female'" src="assets/images/leanmassfemale.png"  class="an-img">
        <img *ngIf="userProfile.data.gender === 'male'" src="assets/images/leanmass.png"  class="an-img">

        <div class="an-details right-arm">
            <img src="assets/images/rightarm.png" style="width: 40px;">
            <p class="head-text">Right Arm</p>
            <p class="head-value">Fat: <b>{{fatdata.right_arm_fat_kg | number : '.2-2'}} Kg</b></p>
            <p class="percent-value"><span id="right-arm-fat"></span></p>
        </div>
        <div class="an-details left-arm">
            <img src="assets/images/leftarm.png" style="width: 40px;">
            <p class="head-text">Left Arm</p>
            <p class="head-value">Fat: <b>{{fatdata.left_arm_fat_kg | number : '.2-2'}} Kg</b></p>
            <p class="percent-value"><span id="left-arm-fat"></span></p>
        </div>
        <div class="an-details trunk">
            <img src="assets/images/trunk.png" style="width: 40px;">
            <p class="head-text">Trunk</p>
            <p class="head-value">Fat: <b>{{fatdata.trunk_fat_kg | number : '.2-2'}} Kg</b></p>
            <p class="percent-value"><span id="trunk-fat"></span></p>
        </div>
        <div class="an-details right-leg">
            <img src="assets/images/rightleg.png" style="width: 40px;">
            <p class="head-text">Right Leg</p>
            <p class="head-value">Fat: <b>{{fatdata.right_leg_fat_kg | number : '.2-2'}} Kg</b></p>
            <p class="percent-value"><span id="right-leg-fat"></span></p>
        </div>
        <div class="an-details left-leg">
            <img src="assets/images/leftleg.png" style="width: 40px;">
            <p class="head-text">Left Leg</p>
            <p class="head-value">Fat: <b>{{fatdata.left_leg_fat_kg | number : '.2-2'}} Kg</b></p>
            <p class="percent-value"><span id="left-leg-fat"></span></p>
        </div>
    </div>
    <div class="analysiscol" fxFlex.gt-sm="50%" *ngIf="this.isPresentInAny">
        <h4 class="heading" style="text-align: center;">Lean Mass Analysis</h4>
        <img *ngIf="userProfile.data.gender === 'female'" src="assets/images/fatanalysisfemale.png" class="an-img">
        <img *ngIf="userProfile.data.gender === 'male'" src="assets/images/fatanalysis.png" class="an-img">
        <div class="an-details right-arm">
            <img src="assets/images/rightarm.png" style="width: 40px;">
            <p class="head-text">Right Arm</p>
            <p class="head-value">Lean Mass: <b>{{fatdata.right_arm_muscle_mass_kg | number : '.2-2'}} Kg</b></p>
            <p class="percent-value"><span id="right-arm-mass"></span></p>
        </div>
        <div class="an-details left-arm">
            <img src="assets/images/leftarm.png" style="width: 40px;">
            <p class="head-text">Left Arm</p>
            <p class="head-value">Lean Mass: <b>{{fatdata.left_arm_muscle_mass_kg | number : '.2-2'}} Kg</b></p>
            <p class="percent-value"><span id="left-arm-mass"></span></p>
        </div>
        <div class="an-details trunk">
            <img src="assets/images/trunk.png" style="width: 40px;">
            <p class="head-text">Trunk</p>
            <p class="head-value">Lean Mass: <b>{{fatdata.trunk_muscle_mass_kg | number : '.2-2'}} Kg</b></p>
            <p class="percent-value"><span id="trunk-mass"></span></p>
        </div>
        <div class="an-details right-leg">
            <img src="assets/images/rightleg.png" style="width: 40px;">
            <p class="head-text">Right Leg</p>
            <p class="head-value">Lean Mass: <b>{{fatdata.right_leg_muscle_mass_kg | number : '.2-2'}} Kg</b></p>
            <p class="percent-value high"><span id="right-leg-mass"></span></p>
        </div>
        <div class="an-details left-leg">
            <img src="assets/images/leftleg.png" style="width: 40px;">
            <p class="head-text">Left Leg</p>
            <p class="head-value">Lean Mass: <b>{{fatdata.left_leg_muscle_mass_kg | number : '.2-2'}} Kg</b></p>
            <p class="percent-value high"><span id="left-leg-mass"></span></p>
        </div>
    </div>
    
</mat-card>
</div>
</div>
</div>
</div>

<!--Mobile Device-->
<div class="mobile" *ngIf="isMobile" id="smartscale">
    <div class="overlay-error" *ngIf="!dataExists">
        <p><img src="assets/images/warning.png" style="width: 50px;"><br>Cannot build report, No Data Found<br>Please take a Smartscale Reading.</p>
      </div>
      <div id="elementToCapture" #elementToCapture>
          <!-- <div id="loader">
              <div class="spinner"></div>
            </div> -->
      <div class="header">
        <img id="logoImage" style="place-self: center; width: 100%;">
          <span></span>
          <p class="heading" style="place-self: center; font-size: smaller;">Scan QR Code to view your reading in your mobile app</p>
          <img src="assets/images/qr.png" style="width:80%; place-self: center;">
      </div>
      <!---Personal Details-->
      <mat-card class="text-center little-profile" style="margin: 0px;">
          <!-- <img mat-card-image src="assets/images/background/profile-bg.jpg" alt="Photo of a Shiba Inu"> -->
          <mat-card-content fxLayout="row" fxLayoutWrap="wrap" style="padding: 10px 25px!important; font-size: 12px;">
              <!-- <div class="pro-img2" fxFlex.gt-xs="15%">
                  <img src="assets/images/users/4.jpg" width="100" alt="user" class="img-circle">
              </div> -->
              <div fxFlex.gt-xs="25%" fxFlex="100" class="profile-block">
                  <!-- <img src="assets/images/age.png" class="profile-icon"> -->
                  <p class="profile-head">Name:</p>
                  <p class="profile-text"> {{userProfile.data.name}}</p>
              </div>
              <div fxLayout="row" fxLayoutWrap="wrap" class="m-t-3" fxFlex.gt-xs="75%">
                  <div fxFlex.gt-sm="25%" fxFlex.gt-xs="33.33%" fxFlex="100" class="profile-block">
                      <!-- <img src="assets/images/age.png" class="profile-icon"> -->
                      <p class="profile-head">Age:</p>
                      <p class="profile-text"> {{age}} yrs</p>
                  </div>
                  <div fxFlex.gt-sm="25%" fxFlex="100" class="profile-block">
                      <!-- <img src="assets/images/height.png" class="profile-icon"> -->
                      <p class="profile-head">Height:</p>
                      <p class="profile-text"> {{userProfile.data.height}} cm</p>
                  </div>
                  <div fxFlex.gt-sm="25%" fxFlex.gt-xs="33.33%" fxFlex="100" class="profile-block">
                      <p class="profile-head">Gender:</p>
                      <p class="profile-text" style="text-transform: capitalize;">{{userProfile.data.gender}}</p>            </div>
                  <div fxFlex.gt-sm="25%" fxFlex.gt-xs="33.33%" fxFlex="100" class="profile-block">
                      <p class="profile-head">Date:</p>
                      <p class="profile-text">{{formattedDateNow}}</p>
                  </div>
              </div>
          </mat-card-content>
      </mat-card>
      <button (click)="generatePDF()" class="print-button heading ">Print</button>
      <div class="upper-section" fxLayout="row" fxLayoutWrap="wrap">
      <div class="analysis-section" fxFlex.gt-sm="50%">
      <!---body-composition-->
      <h3 class="heading sec-head">Body Composition Analysis</h3>
      <mat-card class="card-main">
          <div class="table-row" fxLayout="row" fxLayoutWrap="wrap">
                  <div class="bca-img" fxFlex.gt-sm="10%">
                      <img src="assets/images/bodyfat.png">
                  </div>
                  <div class="bca-text" fxFlex.gt-sm="90%">
                  <div class="bca-details" fxFlex.gt-sm="85%">
                      <span class="heading" style="margin-left: 0px;">Body Fat</span>
                      <p class="m-0">Total fat in your body that include fat around your organs and below your organs and below your skin.</p>    
                  </div>
                  <div class="col-2" fxFlex.gt-sm="15%" style="background-color: #F6F9FC; border-radius: 0rem 1rem 0rem 0rem;">
                  <p>{{userdata.body_fat*weight/100 | number : '.2-2'}}Kg</p>
                  </div>
                  </div>
          </div>
          <div class="table-row" fxLayout="row" fxLayoutWrap="wrap">
              <div class="bca-img" fxFlex.gt-sm="10%">
                  <img src="assets/images/fatfree.png">
              </div>
              <div class="bca-text" fxFlex.gt-sm="90%">
              <div class="bca-details" fxFlex.gt-sm="85%">
                  <span class="heading" style="margin-left: 0px;">Fat Free Weight</span>
                  <p class="m-0">Your muscle, Bone, Body water and other Tissue weight</p>    
              </div>
              <div class="col-2" fxFlex.gt-sm="15%" style="background-color: #F6F9FC;">
              <p>{{userdata.weight-(userdata.body_fat/100)*userdata.weight | number : '.2-2'}}Kg</p>
              </div>
              </div>
      </div>
      <div class="table-row" fxLayout="row" fxLayoutWrap="wrap">
          <div class="bca-img" fxFlex.gt-sm="10%">
              <img src="assets/images/weight.png">
          </div>
          <div class="bca-text" fxFlex.gt-sm="90%" style="border: none;">
          <div class="bca-details" fxFlex.gt-sm="85%">
              <span class="heading" style="margin-left: 0px;">Weight</span>
              <p class="m-0">Sum of the above</p>    
          </div>
          <div class="col-2" fxFlex.gt-sm="15%" style="background-color: #F6F9FC; border-radius: 0rem 0rem 1rem 0rem;">
          <p>{{userdata.weight | number : '.2-2'}}Kg</p>
          </div>
          </div>
      </div>
          <!-- <div class="table-row" fxLayout="row" fxLayoutWrap="wrap">
              <div class="col-1" fxFlex.gt-sm="65%">
                  Fat Free Weight
              </div>
              <div class="col-2" fxFlex.gt-sm="35%">
                  45.59 Kg
              </div>
          </div>
          <div class="table-row" fxLayout="row" fxLayoutWrap="wrap">
              <div class="col-1" fxFlex.gt-sm="65%">
                  Weight (64.60~78.96)
              </div>
              <div class="col-2" fxFlex.gt-sm="35%">
                  58.00 Kg
              </div>
          </div> -->
      </mat-card>
      
      <!--Muscle Fat-->
      <h3 class="heading sec-head" style="margin-top: 15px;">Muscle Fat Analysis</h3>
      <mat-card class="card-main mfa">
          <div class="table-row">
              <div fxLayout="row" fxLayoutWrap="wrap" class="table-row-2">
              <div class="col-1" fxFlex.gt-sm="25%">
                  Weight ({{userdata.weight | number : '.2-2'}}Kg)
              </div>
              <div class="col-2 m-t-5" fxFlex.gt-sm="75%" fxLayout="row" fxLayoutWrap="wrap">
                  <div class="slider-section" fxFlex.gt-sm="20%" width="20%">   
                      <div class="slider slider-1"></div>
                      <p class="slider-text-in">Seriously Low</p>    
                  </div>
                  <div class="slider-section" fxFlex.gt-sm="20%" width="20%">
                      <p class="slider-text-up">{{this.sw * 0.80 | number : '.1-1'}}</p>
                      <div class="slider slider-2"></div>
                      <p class="slider-text-in">Low</p>    
                  </div>
                  <div class="slider-section" fxFlex.gt-sm="20%" width="20%">
                      <p class="slider-text-up">{{this.sw * 0.89 | number : '.1-1'}}</p>
                      <div class="slider slider-3"></div>
                      <p class="slider-text-in">Standard</p>    
                  </div>
                  <div class="slider-section" fxFlex.gt-sm="20%" width="20%">
                      <p class="slider-text-up">{{this.sw * 1.09 | number : '.1-1'}}</p>
                      <div class="slider slider-4"></div>
                      <p class="slider-text-in">High</p>    
                  </div>
                  <div class="slider-section" fxFlex.gt-sm="20%" width="20%">
                      <p class="slider-text-up">{{this.sw * 1.20 | number : '.1-1'}}</p>
                      <div class="slider slider-5"></div>
                      <p class="slider-text-in">Exceed</p>    
                  </div>
                  <!-- <div class="slider-2" fxFlex.gt-sm="20%" width="20%"></div>
                  <div class="slider-3" fxFlex.gt-sm="20%" width="20%"></div>
                  <div class="slider-4" fxFlex.gt-sm="20%" width="20%"></div>
                  <div class="slider-5" fxFlex.gt-sm="20%" width="20%"></div> -->
                  <!-- <p class="slider-text" id="first-text">57.4</p>
                  <p class="slider-text" id="second-text">64.6</p>
                  <p class="slider-text" id="third-text">79.0</p>
                  <p class="slider-text" id="fourth-text">86.1</p> -->
                  <img src="assets/images/marker.png" style="width: 15px; top: 15px;" [style.left.%]="weightslider" class="slider-dot">
              </div>
              </div>
              <div fxLayout="row" fxLayoutWrap="wrap" class="table-row-2">
              <div class="col-1" fxFlex.gt-sm="25%">
                  SMM ({{userdata.skeletal_muscle | number : '.2-2'}}%)<br>
                  <span class="sub-text">(Skeletal Muscle Mass)</span>
              </div>
              <div class="col-2 m-t-5" fxFlex.gt-sm="75%" fxLayout="row" fxLayoutWrap="wrap">
                  <div class="slider-section" fxFlex.gt-sm="33.33%" width="33.33%">
                      <div class="slider slider-2"></div>
                      <p class="slider-text-in">Low</p>    
                  </div>
                  <div class="slider slider-section" fxFlex.gt-sm="33.33%" width="33.33%">
                      <p class="slider-text-up">{{this.smm | number : '.1-1'}}</p>
                      <div class="slider-3"></div>
                      <p class="slider-text-in">Standard</p>    
                  </div>
                  <div class="slider slider-section" fxFlex.gt-sm="33.33%" width="33.33%">
                      <p class="slider-text-up">{{this.smm + 10 | number : '.1-1'}}</p>
                      <div class="slider-4"></div>
                      <p class="slider-text-in">High</p>    
                  </div>
                  <!-- <p class="slider-text" id="first-text" style="left: 32%">49.0</p>
                  <p class="slider-text" id="second-text" style="left: 65%">59.0</p> -->
                  <!-- <img src="assets/images/slider-dot.png" style="width: 21px; top: -5.5px;" [style.left.%]="weightslider" class="slider-dot"> -->
                  <img src="assets/images/marker.png" style="width: 15px; top: 15px;" [style.left.%]="smmslider" class="slider-dot">
              </div>
              </div>
              <div fxLayout="row" fxLayoutWrap="wrap" class="table-row-2">
                  <div class="col-1" fxFlex.gt-sm="25%">
                      BFM ({{ userdata.body_fat*weight/100  | number : '.2-2'}}Kg)<br>
                      <span class="sub-text"> (Body Fat Mass)</span>
                  </div>
                  <div class="col-2 m-t-5" fxFlex.gt-sm="75%" fxLayout="row" fxLayoutWrap="wrap">
                      <div class="slider-section" fxFlex.gt-sm="25%" width="25%">
                          <div class="slider slider-2" ></div>
                          <p class="slider-text-in">Low</p>    
                      </div>
                      <div class="slider-section" fxFlex.gt-sm="25%" width="25%">
                          <p class="slider-text-up">{{bfp*weight/100 | number : '.1-1'}}</p>
                          <div class="slider slider-3"></div>
                          <p class="slider-text-in">Standard</p>    
                      </div>
                      <div class="slider-section" fxFlex.gt-sm="25%" width="25%">
                          <p class="slider-text-up">{{(bfp*weight/100) + 10 | number : '.1-1'}}</p>
                          <div class="slider slider-4"></div>
                          <p class="slider-text-in">High</p>    
                      </div>
                      <div class="slider-section" fxFlex.gt-sm="25%" width="25%">
                          <p class="slider-text-up">{{(bfp*weight/100) + 15 | number : '.1-1'}}</p>
                          <div class="slider slider-5"></div>
                          <p class="slider-text-in">Exceed</p>    
                      </div>
                      <!-- <p class="slider-text" id="second-text" style="left: 23.5%">6.4</p>
                      <p class="slider-text" id="third-text" style="left: 48.5%">12.8</p>
                      <p class="slider-text" id="fourth-text" style="left: 73.5%">15.1</p>    
                      <img src="assets/images/slider-dot.png" style="width: 21px; top: -5.5px;" [style.left.%]="weightslider" class="slider-dot"> -->
                      <img src="assets/images/marker.png" style="width: 15px; top: 15px;" [style.left.%]="bfmslider" class="slider-dot">
      
                  </div>
                  </div>
          </div>
      </mat-card>
      
      <!--Obesity Analysis-->
      <h3 class="heading sec-head" style="margin-top: 15px;">Obesity Analysis</h3>
      <mat-card class="card-main mfa">
          <div class="table-row">
              <div fxLayout="row" fxLayoutWrap="wrap" class="table-row-2">
              <div class="col-1" fxFlex.gt-sm="25%">
                  BMI ({{userdata.bmi | number : '.2-2'}})<br>
                  <span class="sub-text">(Body Mass Index)</span>
              </div>
              <div class="col-2 m-t-5" fxFlex.gt-sm="75%" style="margin-left: 20px;" fxLayout="row" fxLayoutWrap="wrap">
                  <div class="slider-section" fxFlex.gt-sm="33.33%" width="33.33%">
                      <div class="slider slider-2" ></div>
                      <p class="slider-text-in">Low</p>    
                  </div>
                  <div class="slider-section" fxFlex.gt-sm="33.33%" width="33.33%">
                      <p class="slider-text-up">18.5</p>
                      <div class="slider slider-3"></div>
                      <p class="slider-text-in">Standard</p>    
                  </div>
                  <div class="slider-section" fxFlex.gt-sm="33.33%" width="33.33%">
                      <p class="slider-text-up">25.0</p>
                      <div class="slider slider-4" ></div>
                      <p class="slider-text-in">High</p>    
                  </div>
                  <!-- <p class="slider-text" id="first-text" style="left: 32%">18.5</p>
                  <p class="slider-text" id="second-text" style="left: 65%">25.0</p>
                  <img src="assets/images/slider-dot.png" style="width: 21px; top: -5.5px;" [style.left.%]="weightslider" class="slider-dot"> -->
                  <img src="assets/images/marker.png" style="width: 15px; top: 15px;" [style.left.%]="bmislider" class="slider-dot">
      
              </div>
              </div>
              <div fxLayout="row" fxLayoutWrap="wrap" class="table-row-2">
                  <div class="col-1" fxFlex.gt-sm="25%">
                      PBF ({{ userdata.body_fat | number : '.2-2'}}%)<br>
                      <span class="sub-text">(Percent Body Fat)</span>
                  </div>
                  <div class="col-2 m-t-5" fxFlex.gt-sm="75%" style="margin-left: 20px;" fxLayout="row" fxLayoutWrap="wrap">
                      <div class="slider-section" fxFlex.gt-sm="25%" width="25%">
                          <div class="slider slider-2"></div>
                          <p class="slider-text-in">Low</p>    
                      </div>
                      <div class="slider-section" fxFlex.gt-sm="25%" width="25%">
                          <p class="slider-text-up">{{bfp | number : '.1-1'}}</p>
                          <div class="slider slider-3"></div>
                          <p class="slider-text-in">Standard</p>    
                      </div>
                      <div class="slider-section" fxFlex.gt-sm="25%" width="25%">
                          <p class="slider-text-up">{{bfp + 10 | number : '.1-1'}}</p>
                          <div class="slider slider-4"></div>
                          <p class="slider-text-in">High</p>    
                      </div>
                      <div class="slider-section" fxFlex.gt-sm="25%" width="25%">
                          <p class="slider-text-up">{{bfp + 15 | number : '.1-1'}}</p>
                          <div class="slider slider-5"></div>
                          <p class="slider-text-in">Exceed</p>    
                      </div>
                      <!-- <p class="slider-text" id="second-text" style="left: 23.5%">11.0</p>
                      <p class="slider-text" id="third-text" style="left: 48.5%">22.0</p>
                      <p class="slider-text" id="fourth-text" style="left: 73.5%">26.0</p>    
                      <img src="assets/images/slider-dot.png" style="width: 21px; top: -5.5px;" [style.left.%]="weightslider" class="slider-dot"> -->
                      <img src="assets/images/marker.png" style="width: 15px; top: 15px;" [style.left.%]="pdfslider" class="slider-dot">
      
                  </div>
                  </div>
          </div>
      </mat-card>
      <!--Segmental analysis-->
      </div>
      <div class="actofit-health" fxFlex.gt-sm="50%">
      <h3 class="heading sec-head">Actofit Health Score</h3>
      <mat-card class="card-main">
          <!-- <canvas id="actoscore"></canvas> -->
          <div>
          <div class="score-donut">
          <div class="card">
              <div class="percent">
                <!-- <svg>
                  <circle cx="105" cy="105" r="50"></circle>
                  <circle cx="105" cy="105" r="50" [style.--percent]="userdata.health_score"></circle>
                </svg> -->
                <div class="number">
                  <h3 id="score" style="margin: 10px;">{{userdata.health_score | number : '.2-2'}}<span>/100 points</span></h3>
                </div>
              </div>
              <!-- <div class="title">
                  <h2>Points</h2>
                </div> -->
          </div>
      </div>
      <p style="font-style: italic; font-family: 'Roboto', sans-serif; font-size: 0.7rem; text-align: center; border-bottom: 0.45px solid #EAEAEA; width: 90%; margin: auto; padding-bottom: 15px;">*Total score that reflects the evaluation of body composition. A muscular person may score over 100 points.</p>
      
            <div class="score-details">
              <h4 class="heading" style="margin: 0px 10px 10px 10px; font-size: 7px;">Results Interpretation</h4>
              <div class="ind-score" id="ind-score">
                  <h3 class="m-0 sub-heading">Body Composition Analysis</h3>
                  <p class="m-0 sub-text" id="ind-sub">Body weight is the sum of body fat free mass, which is composed of Dry Lean Mass, Total Body Water, Bone Mass & other Tissue</p>
              </div>
              <div class="ind-score">
                  <h3 class="m-0 sub-heading">Muscle-Fat Analysis</h3>
                  <p class="m-0 sub-text">The longer the Skeletal Muscle Mass bar is compared to the Body Fat Mass bar, the stronger the body is</p>
              </div>
              <div class="ind-score">
                  <h3 class="m-0 sub-heading">Obesity Analysis</h3>
                  <p class="m-0 sub-text">BMI is an index used to determine obesity by using height and weight. PBF is the percentage of body fat compared to body weight</p>
              </div>
              <div class="ind-score">
                  <h3 class="m-0 sub-heading">Segmental Body Analysis</h3>
                  <p class="m-0 sub-text">Evaluates whether the amount of muscle and fat is adequately distributed throughout the body</p>
              </div>
                  <div class="ind-score">
                      <h3 class="m-0 sub-heading">Body Composition History</h3>
                      <p class="m-0 sub-text">Track the history of the body compositional change. Take the Actofit Test periodically to monitor your progress.</p>
                  </div>
                  <div class="ind-score" >
                      <h3 class="m-0 sub-heading">Body Fat-Weight Control</h3>
                      <p class="m-0 sub-text">Body Fat Mass : <span class="standard">Standard</span> 
                      Weight: +6.60Kg
                      (+) Means to gain fat/ lean mass · (-) Means to loose fat/lean mass
                      </p>
                  </div>
                  <div class="ind-score">
                      <h3 class="m-0 sub-heading">Bone Mass: {{userdata.bone_mass | number : '.2-2'}}Kg <span id="bone-mass"></span></h3>
                      <p class="m-0 sub-text">This is the weight of total bone tissue (Bone minerals + Bone matrix) in your body. Higher bone mass indicates better bone health.</p>
                  </div>
                  <div class="ind-score">
                      <h3 class="m-0 sub-heading">Body Water: {{userdata.body_water | number : '.2-2'}}% 33.41 Ltr <span class="standard">Standard</span></h3>
                      <p class="m-0 sub-text">It’s the total amount of fluid in the body expressed as % of total weight. Lower body water % affects the essential body processes.</p>
                  </div>
                  <div class="ind-score">
                      <h3 class="m-0 sub-heading">Visceral Fat: {{userdata.visceral_fat}} <span  id="visceral-fat"></span></h3>
                      <p class="m-0 sub-text">Visceral fat is located deep in the core abdominal area, surrounding and protecting the vital organs. Healthy level of visceral fat directly reduces the risk of certainn diseases.</p>
                  </div>
               
                  <div class="ind-score">
                      <h3 class="m-0 sub-heading">Subcutaneous Fat: {{userdata.subcutaneous_fat | number : '.2-2'}}% <span id="subcutaneous-fat"></span></h3>
                      <p class="m-0 sub-text">It is the proportional weight of fat below the skin to the total body weight. Higher subcutaneous fat value is an indicator of bad physical health.</p>
                  </div>
                  <div class="ind-score">
                      <h3 class="m-0 sub-heading">Protein: {{userdata.protein | number : '.2-2'}}% <span id="protein">Low</span></h3>
                      <p class="m-0 sub-text">It is the proportional weight of body protein components to the total body weight. Adequate protein levels are essential for normal body function.</p>
                  </div>
                  <div class="ind-score">
                      <h3 class="m-0 sub-heading">Metabolic Age: {{userdata.metabolic_age}} Yrs <span id="metabolic-age"></span></h3>
                      <p class="m-0 sub-text">It is the predicted age of the metabolism of your body. If your metabolic age higher than your actual age, its an indication that you need to improve metabolic rate.</p>
                  </div>
              
                  <div class="ind-score">
                      <h3 class="m-0 sub-heading">Basal Metabolic Rate: {{userdata.bmr}} kcal <span id="bmr"></span></h3>
                      <p class="m-0 sub-text">The BMR or Basal Metabolic Rate is daily minimum of energy or calories your body requires when at rest (including sleeping) in order to function effectively</p>
                  </div>
            </div>
            </div>
      </mat-card>
      </div>
      </div>
      
      
      <!---Analysis Data-->
      <div class="analysis-section-1" fxLayout="row" fxLayoutWrap="wrap">
      <div class="segmental-graph" fxFlex.gt-sm="50%">
          <h3 class="heading sec-head">Body Composition History</h3>
      <mat-card class="card-main sba" style="height: 280px">
        <div class="graph-overlay" id="graph-overlay">
            <p>The graphical variations will be visible after using the scale more than 5 times</p>
        </div>
          <div class="table-row" fxLayout="row" fxLayoutWrap="wrap">
              <div class="col-1 heading" fxFlex.gt-sm="25%">
                  Weight
              </div>
              <div class="col-2" fxFlex.gt-sm="75%" style="border-radius: 0rem 1rem 0rem 0rem;">
                  <canvas id="weightChart" style="border-radius: 0rem 1rem 0rem 0rem;"></canvas>
              </div>
          </div>
          <div class="table-row" fxLayout="row" fxLayoutWrap="wrap">
              <div class="col-1 heading" fxFlex.gt-sm="25%">
                  SMM <br>
                  <span class="sub-text">Skeletal Muscle Mass</span>
              </div>
              <div class="col-2" fxFlex.gt-sm="75%">
                  <canvas id="smmChart"></canvas>
              </div>
          </div>
          <div class="table-row" fxLayout="row" fxLayoutWrap="wrap">
              <div class="col-1 heading" fxFlex.gt-sm="25%">
                  BFM<br> 
                  <span class="sub-text">Body Fat Mass</span>
              </div>
              <div class="col-2" fxFlex.gt-sm="75%" style="border-radius: 1rem;">
                  <canvas id="bfChart" ></canvas>
              </div>
          </div>
          <div class="table-row" fxLayout="row" fxLayoutWrap="wrap">
              <div class="col-1 heading" fxFlex.gt-sm="25%">
              </div>
              <div class="col-2" fxFlex.gt-sm="75%" style="border-radius: 0rem 0rem 1rem 0rem; background-color: transparent;">
                  <canvas id="testChart" style="background-color: transparent; border-bottom: none;"></canvas>
              </div>
          </div>
      </mat-card>
      </div>
      <div class="segmental" fxFlex.gt-sm="50%">
          <h3 class="heading sec-head">Segmental Analysis</h3>
      <mat-card class="card-main"  fxLayout="row" fxLayoutWrap="wrap" id="segmental-card" style="height: 280px">
          <div class="blur-overlay" id="fat-overlay">
              <p>To see segmental analysis use Smartscale Pro and Pro Max</p>
          </div>
          <div class="analysiscol" fxFlex.gt-sm="50%" *ngIf="this.isPresentInAny">
            <h4 class="heading" style="text-align: center;">Fat Analysis</h4>
            <!-- <img src="assets/images/leanmass.png" class="an-img"> -->
            <img *ngIf="userProfile.data.gender === 'female'" src="assets/images/leanmassfemale.png"  class="an-img">
            <img *ngIf="userProfile.data.gender === 'male'" src="assets/images/leanmass.png"  class="an-img">
            <div class="an-details right-arm">
                <img src="assets/images/rightarm.png" style="width: 40px;">
                <p class="head-text">Right Arm</p>
                <p class="head-value">Fat: <b>{{fatdata.right_arm_fat_kg | number : '.2-2'}} Kg</b></p>
                <p class="percent-value"><span id="right-arm-fat"></span></p>
            </div>
            <div class="an-details left-arm">
                <img src="assets/images/leftarm.png" style="width: 40px;">
                <p class="head-text">Left Arm</p>
                <p class="head-value">Fat: <b>{{fatdata.left_arm_fat_kg | number : '.2-2'}} Kg</b></p>
                <p class="percent-value"><span id="left-arm-fat"></span></p>
            </div>
            <div class="an-details trunk">
                <img src="assets/images/trunk.png" style="width: 40px;">
                <p class="head-text">Trunk</p>
                <p class="head-value">Fat: <b>{{fatdata.trunk_fat_kg | number : '.2-2'}} Kg</b></p>
                <p class="percent-value"><span id="trunk-fat"></span></p>
            </div>
            <div class="an-details right-leg">
                <img src="assets/images/rightleg.png" style="width: 40px;">
                <p class="head-text">Right Leg</p>
                <p class="head-value">Fat: <b>{{fatdata.right_leg_fat_kg | number : '.2-2'}} Kg</b></p>
                <p class="percent-value"><span id="right-leg-fat"></span></p>
            </div>
            <div class="an-details left-leg">
                <img src="assets/images/leftleg.png" style="width: 40px;">
                <p class="head-text">Left Leg</p>
                <p class="head-value">Fat: <b>{{fatdata.left_leg_fat_kg | number : '.2-2'}} Kg</b></p>
                <p class="percent-value"><span id="left-leg-fat"></span></p>
            </div>
        </div>
        <div class="analysiscol" fxFlex.gt-sm="50%" *ngIf="this.isPresentInAny">
            <h4 class="heading" style="text-align: center;">Lean Mass Analysis</h4>
            <img *ngIf="userProfile.data.gender === 'female'" src="assets/images/fatanalysisfemale.png" class="an-img">
            <img *ngIf="userProfile.data.gender === 'male'" src="assets/images/fatanalysis.png" class="an-img">
            <div class="an-details right-arm">
                <img src="assets/images/rightarm.png" style="width: 40px;">
                <p class="head-text">Right Arm</p>
                <p class="head-value">Lean Mass: <b>{{fatdata.right_arm_muscle_mass_kg | number : '.2-2'}} Kg</b></p>
                <p class="percent-value"><span id="right-arm-mass"></span></p>
            </div>
            <div class="an-details left-arm">
                <img src="assets/images/leftarm.png" style="width: 40px;">
                <p class="head-text">Left Arm</p>
                <p class="head-value">Lean Mass: <b>{{fatdata.left_arm_muscle_mass_kg | number : '.2-2'}} Kg</b></p>
                <p class="percent-value"><span id="left-arm-mass"></span></p>
            </div>
            <div class="an-details trunk">
                <img src="assets/images/trunk.png" style="width: 40px;">
                <p class="head-text">Trunk</p>
                <p class="head-value">Lean Mass: <b>{{fatdata.trunk_muscle_mass_kg | number : '.2-2'}} Kg</b></p>
                <p class="percent-value"><span id="trunk-mass"></span></p>
            </div>
            <div class="an-details right-leg">
                <img src="assets/images/rightleg.png" style="width: 40px;">
                <p class="head-text">Right Leg</p>
                <p class="head-value">Lean Mass: <b>{{fatdata.right_leg_muscle_mass_kg | number : '.2-2'}} Kg</b></p>
                <p class="percent-value high"><span id="right-leg-mass"></span></p>
            </div>
            <div class="an-details left-leg">
                <img src="assets/images/leftleg.png" style="width: 40px;">
                <p class="head-text">Left Leg</p>
                <p class="head-value">Lean Mass: <b>{{fatdata.left_leg_muscle_mass_kg | number : '.2-2'}} Kg</b></p>
                <p class="percent-value high"><span id="left-leg-mass"></span></p>
            </div>
        </div>
          
      </mat-card>
      </div>
      </div>
      </div>
</div>

<div className='main' id="vitalscan">
    <div className="report-container gap" style="margin-top:8vh">
        <div className='logo'>
           <!-- {tenant=='sbi' ? <img className='logo' src="./assets/sbi_logo.svg"/>:<img className='logo' src="./assets/actofit_logo.svg"/>} -->
         <!-- <img className='logo' src={logo}/>  -->
        </div>
  <h1>Vital Biomarkers</h1>
  <!-- <div className="user-details">
   <div className="user-field">
     <p><strong>Name:</strong> {data.name.toUpperCase()}</p>
     <p><strong>Height:</strong> {data.height} cm</p>
   </div>
   <div className="user-field">
    <p><strong>Weight:</strong> {data.weight} kg</p> 
     <p><strong>Age:</strong> {data.age}</p>
   </div>
   <div className="user-field">
     <p><strong>Gender:</strong> {data.gender}</p>
     <p><strong>Contact:</strong> {data.phone}</p>
   </div>
 </div> -->
  <table>
    <thead>
      <tr>
        <th className="icon-cell"></th>
        <th>Metric</th>
        <th>Value</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr className="health-metric-row">
        <td className="icon-cell">
        <img src='assets/ecg_heart.svg' className="icon-cell"/>
        </td>
        <td>Heart Rate</td>
        <td>{{bpm}}</td>
        <td>
            <!-- <td className={hrStatus == "RELAX ZONE"
            ? "status-blue"
            : hrStatus == "FAT BURN ZONE"
            ? "status-green"
            : hrStatus == "INTENSE BURN ZONE"
            ? "status-brown"
            : hrStatus == "CARDIO ZONE"
            ? "status-orange"
            : hrStatus == "PEAK ZONE"
            ? "status-red"
            : ""}> -->
            {{hrStatus}}
        </td>
      </tr>
      <tr className="health-metric-row">
        <td className="icon-cell">
        <img src='assets/pulmonology_FILL.svg' className="icon-cell"/>
        </td>
        <td>Oxygen Level</td>
        <td>{{oxygen}}</td>
        <td>
        <!-- <td className={data.rppgData.measurementData.oxygen < 90 ? 'status-red' : data.rppgData.measurementData.oxygen >= 90 && data.rppgData.measurementData.oxygen <= 95 ? 'status-orange' : 'status-green'}> -->
        {{oxygenStatus}}
        </td>
      </tr>
      <tr className="health-metric-row">
        <td className="icon-cell">
        <img src='assets/blood_pressure.svg' className="icon-cell"/>
        </td>
        <td>Blood Pressure</td>
        <td>Systolic: {{systolic}} mmHg, Diastolic: {{diastolic}} mmHg</td>
        <td className="{data.rppgData.measurementData.bloodPressureStatus == 'LOW' ? 'status-red' :  data.rppgData.measurementData.bloodPressureStatus == 'ELEVATED' ? 'status-orange' : 'status-green'}">
       {{bloodPressureStatus}}
        </td>
      </tr>
      <tr className="health-metric-row">
        <td className="icon-cell">
        <img src='assets/ecg.svg' className="icon-cell"/>
        </td>
        <td>Heart Rate Variability</td>
          <td>IBI: {{ibi}} ms, RMSSD: {{rmssd}} ms, SDNN: {{sdnn}} ms</td>
          <!-- <td className={sdnnStatus == 'LOW' ? 'status-red' : sdnnStatus == 'HIGH' ? 'status-orange' : 'status-green'}>
        {sdnnStatus}
        </td> -->
        <td></td>
      </tr>
      <tr className="health-metric-row">
        <td className="icon-cell">
        <img src='assets/neurology.svg' className="icon-cell"/>
        </td>
        <td>Stress Index</td>
        <td></td>
        <td>
        <!-- <td className={data.rppgData.measurementData.stressStatus == 'HIGH' ? 'status-red' : data.rppgData.measurementData.stressStatus == 'LOW' ? 'status-orange' : 'status-green'}> -->
        {{stressStatus}}
        </td>
      </tr>
    </tbody>
    
  </table>
  <table>
    <thead>
      <tr>
        <th className="icon-cell"></th>
        <th>Risk Category</th>
        <!-- {/* <th>Risk Score</th> */} -->
        <th></th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr className="health-metric-row">
        <td className="icon-cell">
        <img src='assets/heart.svg' className="icon-cell"/>
        </td>
        <td>HyperTension Risk Score</td>
 
        <td></td>
        <td> {{range}}</td>
      </tr>
      <tr className="health-metric-row">
        <td className="icon-cell">
        <img src='assets/nephrology.svg' className="icon-cell"/>
          </td>
        <td>Diabetic Risk Score</td>
         <td></td>
         <td>{{diabetesRiskScore}}</td>
      </tr>
    
    </tbody>
  </table>
  <div className='disclaimer'>
 
        <h5>Disclaimer:</h5>
        <p>The information provided by this health report is not a substitute for professional medical advice. It should not be used to diagnose or treat any medical condition.
    If you have any medical concerns, please consult with a healthcare professional.</p>
  </div>
</div>
</div>






